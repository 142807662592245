body {
    background-image: url("/public/background.jpg");
    background-size: cover;
    color: white;
    text-align: center;
    border-color: white;
}

table {
    margin: auto;
    width: 100%
}
th, td, tr {
    text-align: center;
    width: 12%;
    height: 10%
}
tr {
    padding: 10px
}

#halves {
    display: inline-block;
    width: 48%;
    margin-top:15%;
    vertical-align: top;
    height: 100%;
}

#greeting {
    background-color: #262626;
    border-style: solid;
    padding: 3%;
    margin-bottom: 2%;
    width: 90%;
    margin-left: auto;
    margin-right: auto
}
#title, #text,th,td,tr {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}
#title {
    font-size: 23px;
}
#text {
    font-size: 16px;
}
a {
    color:white
}
a:visited {
    color:white
}
#pulse {
    animation: pulse 6s;
    animation-iteration-count: infinite;
}
@keyframes pulse {
    0% { transform: scale(1); }
    20% { transform: scale(2); }
    40% { transform: scale(1); }
    100% { transform: scale(1); }
  }
#blink {
    animation: blink 3s;
    animation-iteration-count: infinite;
}

@keyframes blink {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  