#section1, #section2, #section3, #section4 {
    border-style: solid;
    background-size: cover;
    width: 90%;
    margin: auto;
    margin-bottom:2%;
}
#textbox {
    background-color: rgba(0, 0, 0, 0.5);
}
#section1 {
    background-image: url("../../../public/math.jpg");
}
#section2 {
    background-image: url("../../../public/coding.jpg");
}
#section3 {
    background-image: url("../../../public/test.jpg");
}
#section4 {
    background-image: url("../../../public/crypto.jpg");
}